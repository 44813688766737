header {
    background-color: $nav-dark-blue;
    color: $white;
   
}
.sticky{
    z-index: 1;
}
.menu-icon {
    width: rem-calc(24);
}

.brand-logo {
    padding: 0.938rem 0.75rem;
    max-width: 10.625rem;
    width: 100%;
    @include breakpoint(medium up) {
        padding: 1.25rem 1.1rem;
        max-width: 14.625rem;
        text-align: center;
    }
    i {
        font-size: 1.5rem;
        color: $white;
    }
}

.title-bar {
    padding: 0.5rem 0.8rem;
    @include breakpoint(medium up) {
        padding: 0.5rem 1.8rem;
    }

}

.search-bar {
    position: relative;
    @include breakpoint(xlarge up) {
        height: 100%;
    }
    i {
        position: absolute;
        left: 1.125rem;
        top: 50%;
        transform: translateY(-50%);
        color: $white;
        @include breakpoint(large up) {
            left: 1.875rem;
        }
    }
    input {
        max-width: inherit;
        padding-left: 3.2rem;
        display: block !important;
        background-color: $dark-blue;
        color: $white;
        border: none;
        font-weight: 100;
        box-shadow: none;
        height: 100%;
        min-height: 3.75rem;
        margin-bottom: 0;
        font-size: 1.125rem;
        margin-right: 0;
        @include breakpoint(large up) {
            padding-left: 4.75rem;
        }
        @include breakpoint(xlarge up) {
            height: 100%;
        }
        &::placeholder {
            color: $white;
            font-weight: 500;
        }
    }
    [type='search']:focus {
        background-color: $dark-blue;
        box-shadow: none;
        border: none;
    }
    button{
        position: absolute;
        top: rem-calc(23);
        left: rem-calc(20);
        color: $white;
        cursor: pointer;
    }
}

.top-bar {
    align-items: stretch;
    background-color: inherit;
    border-top: rem-calc(1) solid rgba($white, 0.1);
    padding: 0 0 2.5rem 0;
    flex-flow: row wrap;
    width: 100%;
    @include breakpoint(large down) {
        height: calc(100vh - 4rem);
        position: fixed;
        top: rem-calc(64);
        left: 0;
        z-index: 3;
        background: $black;
        display: block;
        overflow: auto;
    }
    @include breakpoint(small only) {
        top: rem-calc(54);
        height: calc(100vh - 3.375rem);
    }
    @include breakpoint(xlarge up) {
        padding: 0;
        border-top: none;
    }
    ul {
        background-color: inherit;
        position: static;
    }
    &-left {
        padding-top: 0.813rem;
        @include breakpoint(medium up) {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
        @include breakpoint(xlarge up) {
            display: flex;
            justify-content: space-between;
            padding-top: 0;
        }
        .menu {
            flex-flow: column;
            font-family: $heading-font-family;
            @include breakpoint(xlarge up) {
                flex-flow: row wrap;
            }
            &.right {
                .sub-menu{
                  .grid-x{
                    justify-content: flex-start;
                  }
                    .menu-list-items{
                        .expanded{
                            width: 75%;
                            margin: auto;
                            a{
                                &:active , &:focus{
                                    color: #000;
                                }
                            }
                        }
                    }
                }
                >li {
                    padding-top: 0;
                    @include breakpoint(xlarge up) {
                        display: flex;
                    }
                    &:first-child {
                        a {
                            border-left: rem-calc(1) solid rgba($white, 0.1);
                            padding-top: 1rem;
                            padding-bottom: 1rem;
                           
                            .lol-inc-icon-app-pin {
                                opacity: 0.6;
                                @include transition(all);
                            }
                            &:hover {
                                @include breakpoint(xlarge up) {
                                    color: $primary-color;
                                }
                            }
                            
                        }
                    }
                    >a {
                        display: flex;
                        align-items: center;
                    }
                }
            }
            .has-children {
                .list-item-sm {
                    @include breakpoint(xlarge up) {
                        i {
                            display: none;
                        }
                    }
                    .lol-inc-icon-app-pin {
                        display: block;
                        font-size: 2rem;
                    }
                }
            }
            >li {
                &:hover {
                    >a {
                        @include transition(all);
                        @include breakpoint(xlarge up) {
                            color: $primary-color;
                        }
                    }
                }
                padding-top:0.375rem;
                &.opened {
                    >a {
                        @include breakpoint(xlarge up) {
                            color: $dark-blue;
                            background-color: $half-white;
                            @include transition(all);
                        }
                    }
                    .lol-inc-icon-app-pin {
                        @include breakpoint(xlarge up) {
                            color: $dark-blue;
                            background-color: $half-white;
                            @include transition(all);
                        }
                    }
                    i {
                        @include transform(rotate(0deg));
                        @include transition(all);
                    }
                }
            }
            .list-item-sm {
                display: flex;
                justify-content: space-between;
                i {
                    @include transform(rotate(270deg));
                }
                @include breakpoint(medium down){
                    .main-link{
                        white-space: normal;
                        word-break: break-word;
                    }
                }
            }
            >li>a {
                font-size: 1.313rem;
                color: $white;
                padding: 0.813rem 1.5625rem;
                text-transform: uppercase;
                white-space: nowrap;
                letter-spacing: rem-calc(1);
                @include breakpoint(xlarge up) {
                    padding: 1.8rem 1rem;
                    font-size: 0.9375rem;
                    font-weight: 700;
                    letter-spacing: rem-calc(0.5);
                }
                @include breakpoint(xxlarge up) {
                    padding: 1.8rem 1.7rem;
                    font-size: 1rem;
                }
                &.button {
                    color: $black;
                    border: none;
                    font-size: 0.75rem;
                    min-width: auto;
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
    }
    &-right {
        @include breakpoint(large down) {
            padding: 1rem;
        }
        >li {
            padding-top: rem-calc(6);
        }
        @include breakpoint(medium up) {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
}

@include breakpoint(xlarge up) {
    .top-bar-right {
        width: calc(100% - 14.625rem);
        flex: 0 0 auto !important;
        max-width: auto !important;
    }
}

// submenu
.sub-menu {
   
    background-color: $half-white;
    font-family: $body-font-family;
    padding: 1rem;
    position: absolute;
    top: rem-calc(144);
    left: 0;
    z-index: 2;
    width: 100%;
    box-shadow: 0px 1rem 0.5rem -0.7rem rgba(0, 0, 0, 0.251);
    display: none;
    color: $black;
    @include breakpoint(large up){
        padding: 0 rem-calc(25);
        padding-right: 0 !important;
    }
    @include breakpoint(medium up){
      
        .grid-x{
            justify-content: space-between;
        }
        .service-list{
            border: none !important;
        }
    }
    @include breakpoint(large down) {
        position: relative;
        top: 0;
    }
    .button {
        padding: $button-padding;
        margin-bottom: $global-margin;
        font-size: 0.75rem;
        min-width: auto;
    }
    >.grid-x {
        @include breakpoint(large only) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 1fr;
            column-gap: rem-calc(40)
        }
        >.cell {
           @include breakpoint(medium up){
            padding: rem-calc(20) 0;
           }
            @include breakpoint(xlarge up) {
                padding: 1.875rem 1.3rem 2.1875rem 2.2rem;
            }
            &:not(:last-child) {
                @include breakpoint(large up) {
                    border-bottom: none;
                }
                border-bottom: $beige rem-calc(1) solid;
                @include breakpoint(xlarge up) {
                    border-bottom: none;
                    border-right: $beige rem-calc(1) solid;
                }
            }
        }
    }
    h6 {
        color: $dark-yellow;
    }
    .sub-title {
        font-size: 0.875rem;
        font-weight: $font-weight-700;
        color: $dark-yellow;
        display: inline-flex;
        margin-bottom: 1rem;
        text-transform: uppercase;
    }
    .service-list{
        @include breakpoint(xlarge up){
            min-width: rem-calc(300);
        }
    }
    .menu-list-items {
        .card{
            .card-section{
                margin: auto;
            }
        }
       
        @include breakpoint(medium up){
            .split-column{columns:2}
            .split-column-3{columns:3}
            .split-column-4{columns:4}
        }

        ul {
            margin-bottom: 0;
            >li {
                a {
                    color: $black-v2; //cr
                    padding: 0.5rem 0;
                    display: block;
                    font-size: 1rem;
                    text-transform: none;
                    font-weight: $font-weight-700;
                    @include breakpoint(xlarge up){
                        padding:0 0 0.95rem 0;
                        font-size: 1rem;
                        font-weight: $font-weight-500;
                    }
                    &:hover {
                        color: $orange-v2;
                    }
                }
            }
        }
        order: 2;
        @include breakpoint(large only) {
            grid-column: span 2;
            grid-row: 1 / 2;
            order: inherit
        }
        @include breakpoint(xlarge up) {
            order: 1;
            padding-left: 0 !important;
        }
    }
    .menu-cards {
        @include breakpoint(xlarge up){
            padding-top: rem-calc(12) !important;
        }
        order: 1;
        @include breakpoint(large only) {
            grid-column: 3 / 3;
            grid-row: 1 / 3;
            order: inherit
        }
        @include breakpoint(xlarge up) {
            order: 2;
        }
        @include breakpoint(large up) {
            border-left: $beige rem-calc(1) solid;
            padding-left: 1.25rem;
        }
        .card-section{
            padding: 1.2rem 1.5625rem;
        }
        .card-left-text{
            padding-right: rem-calc(12);
            max-width: 50%;
            word-break: break-word;
            text-align: right;
        }
        .card-right-text{
            padding-left: rem-calc(12);
            position: relative;
            max-width: 50%;
            word-break: break-word;
         
            &::before{
                content: '/';
                position: absolute;
                left: rem-calc(-7);
                font-weight: $font-weight-700;
                font-size: 1.75rem;
            }
        }
        .card-title{
                display: block;
                color: $black-desc;
        }
        p{
                font-size: rem-calc(14);
                color: $black-desc;
        }
        .no-bg-btn{
            .expanded{
                position: relative;
                z-index: 1;
                &::before{
                    position: absolute;
                    content: '';
                    background: $half-white;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: -1;

                }
            }
        }
        .button{
            width: 75%;
            margin: 1rem auto;
        }
        .sub-title{
            margin-left: rem-calc(-10);
        }

    }
    .app-img{
       img{
        height: rem-calc(50) !important;
        width: rem-calc(220);
        object-fit: scale-down;
       }
    }
    .menu-slider {
        @include breakpoint(xlarge up){
            max-width: rem-calc(500);
        }
        order: 3;
        @include breakpoint(large only) {
            grid-column: span 2;
            grid-row: 2 / 2;
            order: inherit
        }
        @include breakpoint(xlarge up) {
            order: 3;
        }
        .sub-title{
            margin-bottom: 1rem;
        }
        .slider-image{
            background-size: cover;
            background-position: center;
        }
        
        .slider-text {
            padding: rem-calc(20);
            .sub-title {
                font-size: 0.75rem;
                margin-bottom: 0;
            }
            .text-black{
                display: block;
                font-weight: $font-weight-700;
                font-size: rem-calc(20);
            }
        }
    }
}


/* HAMBURGER STYLES */

.visuallyHidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: rem-calc(1);
    width: rem-calc(1);
    margin: rem-calc(-1);
    padding: 0;
    border: 0;
}

.menu-label {
    display: flex !important;
    align-items: center;
}

.hamburger {
    margin: 0 auto;
    width: rem-calc(30);
    height: rem-calc(30);
    @include breakpoint (medium up){
    height: 1.9rem;
    }
    position: relative;
    right: rem-calc(10);
    .bar {
        padding: 0;
        width: rem-calc(30);
        height: rem-calc(3);
        background-color: $white;
        display: block;
        border-radius: rem-calc(4);
        @include transition(all 0.4s ease-in-out);
        position: absolute;
    }
    .bar1 {
        top: rem-calc(5);
    }
    .bar2,
    .bar3 {
        top: rem-calc(13.5);
    }
    .bar3 {
        right: 0;
    }
    .bar4 {
        bottom: 0;
    }
    &.hamburger4 {
        .bar {
            transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;
        }
        .bar2 {
            width: rem-calc(1);
            @include transform(rotate(90deg));
            left: rem-calc(13.5);
        }
        .bar3 {
            width: rem-calc(1);
            left: rem-calc(13.5);
        }
        .bar4 {
            top: rem-calc(13.5);
        }
        .bar5 {
            bottom: rem-calc(4);
        }
    }
}



/* HAMBURGER 4 */



.hamburger4.close{
    >.bar1{
        top: rem-calc(13.5);
        background-color: transparent;
    }
    >.bar2 {
        left: 0;
        width: rem-calc(30);
        @include transform(rotate(45deg));
    }
    >.bar3 {
        left: 0;
        width: rem-calc(30);
        @include transform(rotate(-45deg));
    }
    .bar4 {
        background-color: transparent;
    }
    .bar5 {
        bottom: rem-calc(13.5);
        background-color: transparent;
    }
    
}



.mobile-menu{
    margin-left:auto;
    button{
        min-width: auto;
        margin-bottom: 0;
    } 
}
.overflowToggle{
    overflow: hidden;
}

@include breakpoint(medium) {
    //Disable fixed top bar on mobile
    .sticky.is-stuck {
        position: relative;
    }
}


//searchbar autocomplete styles
// Change autocomplete styles in WebKit 
.search-bar input:-webkit-autofill,
.search-bar input:-webkit-autofill:hover, 
.search-bar input:-webkit-autofill:focus
{
   -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #0000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }


//clear button



.cross{
    right: 0.9rem;left:auto !important;text-decoration: underline;background-color: #333c55;
    @include breakpoint(medium up){
        right: 0.8rem;
    }
}

//signin code

.top-bar-left .menu > li form {
    padding: 1.8rem 1.7rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    cursor:pointer;
    @include breakpoint(medium down){
     font-size: 1.313rem;
    }
}
    .top-bar-left .menu > li form button {
        cursor: pointer
    }
.top-bar-left .menu > li > form:hover .text-dark {
    color: #F7C900 !important;
}