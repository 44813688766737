
//events-and-meetings
.events-meetings {
    .member-dropdown {
        padding: 1rem;
        @include breakpoint(medium up) {
            padding: 1rem 3rem !important;
        }
    }
  }
  
  .events-data {
    border-top: 1px solid $light-border;
    padding: rem-calc(30) rem-calc(20);
    .button {
        font-weight: $font-weight-700;
        font-family: $body-font-family !important;
        font-size: 0.9rem !important;
        @include breakpoint(medium up) {
            margin-bottom: 0;
        }
    }
    &:last-child {
        border-bottom: none;
    }
    .download-card-desc {
        font-size: 1rem;
    }
    .events-block {
        h3 {
            text-transform: capitalize;
            margin-bottom: 1rem;
        }
        .active {
            color: $bright-yellow;
        }
    }
  }
  
  .events-title {
    padding: 1rem;
    @include breakpoint(medium up) {
        padding: 2rem;
    }
  }
  