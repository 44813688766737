
.image-post{
        margin-bottom: 1.125rem;
    .post-img{
        @include breakpoint(medium up){
            width: 100%;
        }
       
    }
}

.reveal-overlay{
    .video-thumbnail{
        position: static !important;
        z-index: 0 !important;
    }
    .overlay{
        img{
            height: 70vh !important;
            object-fit: cover;
            width: 100%;
        }
    }
    .video-player{
        video{
         
                height: 65vh !important;
         
        }
    }
}
.video-post{
    position: relative;
    z-index: 0;
    .video-thumbnail{
        margin-bottom: rem-calc(20);
        position: absolute;
        z-index: 2;
        height: 100%;
        width: 100%;
        color: $white;
        &-content{
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform (translate(-50%,-50%));
            p{
                
                text-transform: uppercase;
                letter-spacing: rem-calc(2);
                @include breakpoint(medium down){
                    font-size: 0.875rem;
                }
            }
        }
        .overlay {
            width: 100%;
            height: 100%;
            &::after{
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba($black , 0.38);
            position: absolute;
            top: 0;
            left: 0;
            }
           img{
            width: 100%;
            height: 100%;
            object-fit: cover;
           }
            
        }
    } 
    &.full-width{
    margin-bottom: 2rem;
    text-align: center;
    z-index: 0;
    .video-player{
        max-height: rem-calc(500);
        height: calc(100vh - 250px);
        padding: 20px;
        overflow: hidden;
        position: relative;
    }
    video{
        position: absolute;
        left :0;
        top:0;
        right:0;
        width:100%;
        height:100%;
        margin:auto;
    }
    .video-thumbnail{
        margin-bottom: rem-calc(20);
        position: relative;
        color: $white;
        &-content{
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform (translate(-50%,-50%));
            p{
                
                text-transform: uppercase;
                letter-spacing: rem-calc(2);
                @include breakpoint(medium down){
                    font-size: 0.875rem;
                }
            }
        }
        .overlay {
            height: 100%;
            width: 100%;
            &::after{
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba($black , 0.38);
            position: absolute;
            top: 0;
            left: 0;
            }
            img{
                width: 100%;
                height: calc(100vh - 250px);
                max-height: rem-calc(563);
            }
            
        }
    } 
}   
}

.quick-links-sec{
    padding: 1.5rem;
    a{
        font-weight: $font-weight-700;
        display: block;
        margin-bottom: $global-margin;
        &:last-child{
            margin-bottom: 0;
        }
    }
    
}