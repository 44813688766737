.footer{
    background: $peach;
    .footer-content{
        padding: rem-calc(25);
    
        text-align: center;
        
        .tagline{
        text-transform: uppercase;
        letter-spacing: 0.125rem;
        color: $orange;
        font-weight: $font-weight-400;
         font-size: rem-calc(18);
        }
    }
   .links{
    word-break: break-word;
    max-width: 80%;
    margin: auto;
    padding-top: 0;
   }
    .p-text-sm{
        color: $footer-black;
        &:hover{
        color: $bright-yellow;
        }
    }
    p{
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: rem-calc(18);
        
        color: $footer-black;
    }
    a{
       text-transform: capitalize;
       text-decoration: underline;
       margin-bottom:0;
       font-size: rem-calc(18);
       color: $orange-v2; //cr
       font-weight: $font-weight-700;
       @include breakpoint(small down){
        display: block;
        margin-bottom: rem-calc(10);
       }
      @include breakpoint(medium up){
        margin-right: 1rem;
        margin-bottom: 1rem;
        display: inline-block;
      }
      @include breakpoint(large up){
        margin-right: 4rem;
        margin-bottom: 1rem;
        display: inline-block;
      }
       &:hover{
        color: $black-v2; //cr
       }
       &:focus{
        color: $orange-v2;
       }
       &:last-child{
        margin-right: 0;
       }
    }
}


//sign out

.signOut{
  input{
    &:hover{
      color: $black-v2 !important;
      cursor: pointer;
    }
  }
}