.banner-slider {
  .slider-image {
    img {
      height: 100%;
      object-fit: cover;
      width: auto;
    }
  }
  .slider-text {
    padding: 2.5rem 2.25rem;
    h6 {
      font-size: 0.75rem;
    }
    h2 {
      margin-bottom: 0.625rem;
      font-size: 2.375rem;
    }
    .content-placeholder {
      line-height: 1.375rem;
      font-size: 1.125rem;
      margin-bottom: 0.938rem;
    }
    .button-group {
      margin-bottom: 0;
    }
    .share-link-btn {
      background-color: $white;
      color: $light-blue;
      font-size: 0.625rem;
    }
  }
  .orbit-bullets {
    position: absolute;
    top: rem-calc(18);
    left: rem-calc(-35);
    @include transform(90deg);
    margin: 0;
    background-color: $white;
    display: flex;
    justify-content: center;
    padding: rem-calc(10);
    button {
      height: 0.563rem;
      width: 0.563rem;
      &.is-active {
        background-color: $orange;
      }
    }
  }
}

.banner-small {
  position: relative;
  z-index: 0;
  padding-bottom: rem-calc(1);
  padding-top: rem-calc(18);
  min-height: 12.75rem;
  width: 100%;
  background-size: cover;
  
  background-color: $white;
  @include breakpoint(large up) {
    min-height: rem-calc(380);
  }
  
  .applications-title {
    @include flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    padding: rem-calc(10) rem-calc(15);
    @include breakpoint(medium up) {
      margin-bottom: 1.9rem;
    }
    @include breakpoint(large up) {
      margin-bottom: 1rem;
      padding: 2rem;
    }
    .main-title-lg{
      @include breakpoint(medium down) {
        font-size: 1.4rem;
      }
    }
  }
  .banner-overlap {
    position: relative;
    margin-bottom: rem-calc(-116);
  }
  .risk-content-title {
    .risk-info {
      font-weight: $font-weight-700;
      color: $orange;
    }
  }
}
//banner height
.inner-page-banner {
  min-height: 18.75rem;
  @include breakpoint(large up) {
    min-height: rem-calc(331);
  }
  .banner-overlap-cards{
    .card{
      .card-title{
        margin-bottom: 0;
      }
      p{
        margin-bottom: 1rem;
      }
    }
  }
}

.banner-overlap-cards + * {
  @include breakpoint(large up){
    margin-top: -14rem;
  }

}


.bgimg-page{
  background-size: contain;
  background-repeat:no-repeat ;
}