//hero-carousel

.ms-hero-carousel{
    background-size: cover;
    background-repeat: no-repeat;
    @include breakpoint(medium down){
        margin-bottom: $global-margin;
        }
    @include breakpoint(medium down){
    background-image: none !important;
    padding: 0;
    }
   @include breakpoint(large up){
     min-height: rem-calc(500);
   }
   &-container{
    @include breakpoint(medium down){
        padding: 0;
        }
   }
    .hero-sec{
        .tns-outer{
            position: relative;
        }
        .tns-controls{
            display: none;
        }
        .tns-outer{
            .tns-nav{
                @include breakpoint(large up){
                    text-align: center;
                    position: absolute;
                    top: 100%;
                    width: 100%;
                    margin-top: rem-calc(10);
                }
                @include breakpoint(xlarge up){
                    width: auto;
                    margin-top:0;
                }

                text-align: center;
                @include breakpoint(medium down){
                    margin-top: $global-margin;
                }
                @include breakpoint(xlarge up){
                position: absolute;
                left: 0;
                top: 0;
                transform: translateX(-100%);
                text-align: left;
                z-index: 0;
                background-color: $white;
                display: flex;
                flex-flow: column;
                padding: rem-calc(15.5);
                }
                > button{
                    
                    @include breakpoint(large down){
                       margin: 0.3rem;
                    }
                    width: rem-calc(9);
                    height: rem-calc(9);
                    padding: 0;
                    box-sizing: border-box;
                    background: $grey;
                    margin-bottom: rem-calc(6);
                    border-radius: 50%;
                    &.tns-nav-active{
                        background: $dark-yellow;
                        }
                        
                    }
                    :last-child{
                        margin-bottom: 0;
                    }
                }
        }
    }
    .ms-slider{

        @include breakpoint(large up){
            margin-bottom: 8rem;
            background: $white;
          
        }
        .slide-section{
            background: #f5f4f1;
            min-height: rem-calc(400);
            display: inline-flex !important;
            transition-duration: 0.5s !important;
            animation-duration: 0.5s !important;
            animation-delay: 0.1s !important;
            transition-delay: 0.2s !important;
            animation-timing-function: ease-in-out;
            .carousel-image{
                @include breakpoint(large up){
                    position: relative;
                }
                img{
             
                   @include breakpoint(large up){
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                   }
                }
            }
          
            @include breakpoint(large down){
                img{
                    width: 100%;
                    object-fit: cover;
                  }
            }
            
            i{
                padding-left: 0.5rem;
            }
            .slide-content{
                @include breakpoint(small down){
                 position: absolute;
                 bottom: 0;
                }
                .card{
                    margin-bottom: 0;
                    .sub-title{
                        margin-bottom: 0;
                        font-size: 0.875rem; //cr
                        font-family: $body-font-family;
                        @include breakpoint(small down){
                            color: $white;
                        }
                    }
                    @include breakpoint(small down){
                        background-color: $transparent;
                        margin-bottom: 0;
                        .main-heading{
                            color: $white;
                            font-size: 1.7rem;
                        }
                        .button{
                            padding: 1rem 3rem;
                            font-size: rem-calc(14);
                           
                        }
                    }
                    &-section{
                        padding: rem-calc(15);
                        @include breakpoint(small down){
                            @include gradient-black;
                        }
                        
                        @include breakpoint(medium up){
                            padding: rem-calc(35);
                        }
                        .main-heading{
                            text-transform: inherit;
                            margin-bottom: 0.5rem;
                            font-size: rem-calc(38);
                        }
                        p{
                            font-size: 1rem; //cr
                            font-weight: $font-weight-300; //cr
                           
                        }
                        .button{
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
        
    }
}



//events carousel
.ms-events-carousel{

    .events-carousel{
        display: flex;
        padding-top: 1.5rem;
        .tns-item{
           display: flex !important;
        }
        .card{
            cursor: pointer;
            padding-right: 0 !important; 
            box-shadow: inset rem-calc(0) rem-calc(0) rem-calc(80) rem-calc(0) rgba(0, 0, 0, 0.11),
             rem-calc(0) rem-calc(0) rem-calc(30) rem-calc(0) rgba(0, 0, 0, 0.16);
            display: flex !important;
            flex-direction: column;
            align-items: center;
            &-section{
                text-align: center;
                align-items: center;
                padding: 1.875rem 1.25rem;
            
               h3{
                color: $black;
                margin-bottom: 0.5rem;
                pointer-events: none;
                user-select: none;
                @include breakpoint(medium down){
                    font-size: 1.2rem;
                }
               }
                
                p{
                    pointer-events: none;
                user-select: none;
                    @include breakpoint(medium down){
                        font-size: 0.7rem;
                    }
                }
            }
            &.active{
                background-color: $bright-yellow;
                h3{
                    color: $white;
                };
            }
        }
        
    }
}

.ms-events-carousel .tns-outer [aria-controls],.ms-events-carousel .tns-outer [data-action]{
    display: none;
}


.banner-slider {
    box-shadow: $card-drop-shadow;
    @include breakpoint(medium up) {
        min-width: rem-calc(290);
    }
  }