
.single-select2{
    position: relative;
  }
    .select2-search--dropdown{
      padding: 0 !important;
    }
    .select2-results__options li:first-child{
      // text-transform: uppercase !important;
      // font-weight: 700;
      font-size: 12px;
      /* border-bottom: 2px solid #e6e6e6; */
    }
    .select2-container--default .select2-results__group{
      padding: 10px;
    }
  
  .select2-results__option {
    padding: 7px 20px;
    vertical-align: middle;
    font-size: 12px;
      font-weight: 500;
      text-transform: capitalize;
      
  }
  // .select2-container--default .select2-results__option .select2-results__option{
  //   padding-left:2rem ;
  // }
  .select2-dropdown{
   border: none; 
  }
  #select2-multiple-results, #select2-single-group-results{
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-top: none;
  }
  .select2-results__option:before {
    display: inline-block;
      width: 1rem;
      height: 1rem;
      pointer-events: none;
      content: "";
      background-color: #FFFFFF;
      border: #adb5bd solid 1px;
      border-radius: 0.25rem;
      margin: 0 15px;
    
  }
  .select2-results__option[aria-selected=true]:before {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") !important;
   
      width: 1rem;
      height: 1rem;
      content: "";
      background: black;
  }
  .select2-container--default .select2-results__option[aria-selected=true] {
      background-color: #fff;
  }
  .select2-container--default .select2-results__option--highlighted[aria-selected] {
      background-color: #eaeaeb;
  
    display: block;
      width: 100%;
    
      clear: both;
  
      color: #212529;
      text-align: inherit;
      // white-space: nowrap;
      background-color: transparent;
      border: 0;
  }
  .select2-container--default .select2-selection--multiple {
      margin-bottom: 0px;
  }
  .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
      border-radius: 4px;
  }
  .select2-container--default.select2-container--focus .select2-selection--multiple {
      border-color: #f77750;
      border-width: 2px;
    padding: 0.625rem;
      background: #f5f4f1;
      border: none;
      cursor: pointer;
      position: relative;
      font-size: 0.75rem;
      font-weight: 700;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 0;
  }
  .select2-container--default .select2-selection--multiple {
      border-width: 2px;
    padding: 0.625rem;
      background: #f5f4f1;
      border: none;
      cursor: pointer;
      position: relative;
      font-size: 0.75rem;
      font-weight: 700;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: flex;
     
  }
  .select2-container--default .select2-search--inline .select2-search__field::placeholder{
     color: #272727;
     text-transform: uppercase;
     font-weight: 700;
  }
  /* 
  .select2-container--open .select2-dropdown--below {
      
      border-radius: 6px;
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
  
  } */
  .select2-selection .select2-selection--multiple:after {
      content: 'hhghgh';
  }
  
  .select2-container--default .select2-search--inline .select2-search__field{
    margin: 0;
    height: 1.5rem;
    font-size: 0.7rem !important;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered li{
    margin: 0.125rem 0.25rem 0.125rem 0;
      padding: 0px 5px 0px 0.5em;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      height: 1.875em;
      color: var(--fms-badge-text-color);
      background-color: var(--fms-badge-color);
      border-radius: 1.1em;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      vertical-align: baseline;
      position: relative;
      z-index: 2;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-search--inline{
    background-color: transparent;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__clear{
      cursor: pointer;
      font-weight: bold;
      background: #f5f4f1;
      width: 21px;
      height: 26px;
      position: absolute;
      right: 2px;
      z-index: 1;
      padding-left: 5px;
      top: 2px;
      font-size: 18px;
      margin-right: 2px !important;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove{
    margin-right: 5px !important;
    color: white !important;
  }
  .select2-container--default .select2-results__group{
    border: none;
    font-size: 16px;
  }
  
  
  /* single-select2 */
  .select2-container--default .select2-selection--single .select2-selection__rendered{
    color: #020001;
  }
  
  #select2-single-results .select2-results__option:before{
    display: none;
  }
  
  #select2-single1-results .select2-results__option:before{
    display: none;
  }
  
  #select2-single-group-results .select2-results__option:before{
    display: none;
  }

  #select2-memberByLocation-results .select2-results__option:before{
    display: none;
  }
  #select2-eventType-results .select2-results__option:before{
    display: none;
  }
  
  #select2-memberByRegion-results .select2-results__option:before{
    display: none;
  }
  #select2-eventLocation-results .select2-results__option:before{
    display: none;
  }
  #select2-leaders-results .select2-results__option:before{
    display: none;
  }
  #select2-leaderCards-results   .select2-results__option:before{
    display: none;
  }
  #select2-needHelp-results   .select2-results__option:before{
    display: none;
  }

  .select2-search--dropdown .select2-search__field{
    display: none
  }
  .select2-container--default .select2-selection--single{
    padding: 0.5rem;
      background: #f5f4f1;
      border: none;
      cursor: pointer;
      display: flex;
      font-size: 0.75rem;
      font-weight: 700;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      display: flex;
      min-height: 40px;
      position: relative;
  }
  
  .select2-container--default .select2-selection--single .select2-selection__clear{
    display: none;
  }
  .select2-container--default .select2-selection--single .select2-selection__rendered{
    font-size: 0.7rem;
    text-transform: uppercase;
    display: flex !important;
    color: #020001 !important;
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow{
    display: none;
  }
  .select2-container--default .select2-results__option[aria-selected=true]{
    background-color: #f5f4f1 !important;
  }
  .single-select2 .lol-inc-icon{
    position: absolute;
      top: 0.625rem;
      right: 0.625rem;
      pointer-events: none;
  
  }
  .select2-container{
    width: 100% !important;
  }
  .select2-container .select2-selection--single{
    background-color: #f5f4f1 !important;
    border-radius: none !important;
    border: none !important;
    display: flex !important;
  }
  .select2-container--default .select2-results__option--highlighted[aria-selected]{
    color: #020001 !important;
    background-color: #f5f4f1 !important;
    border-bottom: 1px solid #ccc;
  }
  .select2-container--default.select2-container--focus .select2-selection--multiple{
    border: none !important;
    padding:5px;
  }
  .select2-container--default .select2-selection--multiple{
    background-color: #f5f4f1 !important;
    border: none !important;
    border-radius: 0;
    padding: 5px 10px;
  }
  .select2-container--default.select2-container--open.select2-container--above .select2-selection--single{
    display: flex;
  }
  .select2-container--open .select2-dropdown--below{
    border: none;
  }

  .select2-results__option{
    border-bottom: 1px solid #ccc;
  }
  .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #020001 !important;
}

.select2-container .select2-selection--single .select2-selection__rendered{
  padding-left: 0 !important;
}