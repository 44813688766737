.card {
   
    border: none;
    
    .card-section{
        display: flex;
        flex-flow: column wrap;
        padding: 1.5rem;
       
        h6 {
            font-size: 0.875rem; //cr 
            margin-bottom: 1rem;
            color: $dark-yellow;
        }
        .button{
          margin-top: auto;
        }
        .card-title {
            margin-bottom: 0;
            font-size: 1.75rem;
            font-weight: $font-weight-700;
            color: $black;
            font-family: $heading-font-family;
            display: block;
            white-space: normal;
            word-break: break-word;
        }
        .sub-title {
            font-size: 0.875rem;
            font-weight: $font-weight-700;
            color: $dark-yellow;
            display: inline-flex;
            margin-bottom: 1rem;
            text-transform: uppercase;
        }
        p{
            font-size: rem-calc(14);
            color: $black-desc;
            line-height: 1.625rem; //cr
           
       
        }
        .p-text-normal{
            font-size:  0.875rem; 
            @include breakpoint(large up){
                font-size:  1.125rem; 
            }
        }
     }
     .img-placeholder {
      position: relative;
      .card-image {
        height: rem-calc(175);
        object-fit: cover;
        width: 100%;
      }
      .card-title {
        position: absolute;
        color: rgb(255, 255, 255);
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        padding: 1rem;
      }
    }

}
.banner-overlap-cards {
  .card {
    &-section {
      .sub-title {
        margin-bottom: rem-calc(40);
      }
    }
  }
}
// approach-card
.approach-card {
  height: 100%;
  justify-content: space-between;
  padding: 3rem;
  &:hover, &:focus{
    color: $white;
  }
  @include breakpoint(large up) {
    padding: 3rem 3.5rem;
  }
  .lol-inc-icon {
     font-size: 2.5rem; 
     margin-top: auto;
   }
}
//article-cards
.card-lg{
  h3{
    font-size: 1.75rem;
    text-transform: capitalize;
  }
  p{
    font-size: 1.125rem !important;
  }
}
.card-sm{
  h3{
    font-size: 1.5rem;
    line-height: 1.5625rem;
    text-transform: capitalize;
  }
  p{
    font-size: 0.875rem;
    color: #020001;
  }
  .button{
    max-width: rem-calc(265); 
  }
}
  .address-cards{
    margin-top: 1rem;
    @include breakpoint(medium up){
    margin-top: 3rem
    }
   .card-section{
    justify-content: space-between;
   }
  }



  .banner-pad {
    @include breakpoint(medium down) {
        margin-top: 3rem;
    }
    .banner-overlap-cards {
        @include breakpoint(large up) {
            margin-top: 0;
            transform: translateY(-50%);
            position: relative;
        }
        .card {
            .card-section {
                .button {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
        .dateTotext {
            margin-bottom: 0;
        }
        @include breakpoint(medium down) {
            position: relative;
            z-index: 0;
            margin-top: -2rem;
        }
        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: rem-calc(100);
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
  }
  
  .data-center {
    margin-bottom: rem-calc(15);
    justify-content: flex-start;
    text-align: left;
    @include breakpoint(medium up) {
        justify-content: center;
        text-align: center;
    }
  }
  
  
//banner content overlap
.banner-overlap-low {
  margin-top: -8rem;
}

.banner-overlap-cards {
  @include breakpoint(large) {
      transform: translateY(-50%);
  }
  
}
.home-overlap-cards{
  .banner-overlap-cards{
    &::before {
      position: absolute;
      content: "";
      background: #f5f4f1;
      width: 100%;
      height: 100%;
      transform: translateY(50%);
      z-index: -1;
  }
  }
}

.overlap-from-top {
  padding-top: rem-calc(20);
  position: relative;
  z-index: 0;
}

.img-overlap-layout {
  position: relative;

  .banner-small {
      position: absolute;
      height: calc(100vh - 350px);
      z-index: 0;
      max-height: rem-calc(380);
  }
}


.overlap-section {
  @include breakpoint(large up) {
      margin-top: -8rem;
  }
  .sub-title {
      margin-bottom: 1rem;
  }
  p {
      font-size: 1rem;
      font-weight: $font-weight-300;
  }
  .button {
      margin-bottom: 0;
  }
}

.section-overlap {
 @include breakpoint(large up){

  margin-top: -8rem !important;
  background-color: transparent !important;
  position: relative;
 }
  .overlap-section {
      margin-top: 0;
  }
  &::before {
      content: "";
      position: absolute;
      background: #f5f4f1;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: -1;
  }
}