.button{
    font-size: 0.9rem; //cr
    text-transform: uppercase;
    font-weight: $font-weight-700;
    font-family: $button-font-family;
    @include breakpoint(large up){
        min-width: rem-calc(240);
    }
 
    &:hover{
        background-color: $black;
        border: 1px solid $black;
        color: $primary-color;
    }
    &:focus{
        background-color: $bright-yellow;
        color: $black;
        border: none;
    }
    &.light-blue{
        color: $light-blue;
        &:hover{
            color: $dark-blue;
        }
    }
}
.link{
    color: $orange-v2 ; //cr
    text-decoration: underline;
    &:hover , &:focus{
        color: $black-v2 !important;
    }
}
.link-yellow{
    color: $bright-yellow;
    text-decoration: underline;
    font-weight: $font-weight-700;
    word-break: break-all;
}
.global-btn{
    display: flex;
    justify-content: center;
   .button{
    max-width: rem-calc(265);
   }
}

.img-tag{
    position: absolute;
    top: 1.25rem;
    left: 1.25rem;
    color: #ffffff;
    min-width: auto;
    background-color: $bright-yellow;
    text-transform: uppercase;
    font-weight: $font-weight-700;
    font-family: $heading-font-family;
    font-size: 0.75rem;
    -webkit-appearance: none;
    line-height: 1;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 1rem 0;
    padding: 1.125rem 1.25rem;
}
.video-close-btn{
    right: 0.2rem;
    top: 0;
    text-transform: uppercase;
    text-decoration: underline;
    color: #F7C900;
    font-size: 1rem;
    font-weight: 700;
}


.button:hover {
    background-color: #020001 !important;
    color: #F7C900 !important;
}