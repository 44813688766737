
$bright-yellow: #F7C900;
$dark-yellow: #B2752D;
$dark-blue: #333c55;
$half-white: #f5f4f1;
$card-drop-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.251);
$btn-padding: 1.125rem 1.25rem;
$beige: #e6e0cc;
$title-margin: 1.25rem;
$orange: #B2752D;
$half-blue: #415899;
$black-alt: #020001;
$black-desc: #020001;
$light-blue: #8099b3;
$grey: #d8d8d8;
$light-white: rgba(255, 255, 255, 0.35);
$white: #fff;
$orange2: #B2752D;
$footer-black: #020d2b;
$transparent: #0000;
$peach: #e5dcc2;
$grey-light: #cdcac1;
$grey-dark: #9d9d9d;
$grey-dark-format: #969696;
$nav-dark-blue : #020D2B;
$transparent-bg : #00000000;
$dark-border : #e5e2dc;
$light-border : #D6CEB4 ;
$black-v2 : #0E121D;
$orange-v2 : #A46F33;
$light-orange : #E8B274;


//font-weight
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-normal: normal;


//select dropdown
$background: #e74c3c;
$select-color: #fff;
$select-background: #c0392b;
$select-width: 100%;
$select-height: 2.5rem; 
