
.dark-blue-bg {
    background-color: $dark-blue;
    color: $white;
    margin-bottom: 2rem;
  }
  
  .half-blue-bg {
    background-color: $half-blue;
    color: $white;
    margin-bottom: 2rem;
  }
  
  .dark-blue-bg,
  .half-blue-bg {
    .sub-title-lg {
        color: inherit;
    }
  }
  