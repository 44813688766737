/* Mixins */

a{
  color: #A46F33;
  &:hover{
    color: #0E121D;
  }
  &:active , &:focus{
    color : $light-orange;
  }
  
}

// Box Shadow
@mixin box-shadow($box-shadow) {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  -ms-box-shadow: $box-shadow;
  -o-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

@mixin inset-box-shadow($top, $left, $blur, $color, $inset: "") {
  -webkit-box-shadow: $top $left $blur $color #{$inset};
  -moz-box-shadow: $top $left $blur $color #{$inset};
  -ms-box-shadow: $top $left $blur $color #{$inset};
  -o-box-shadow: $top $left $blur $color #{$inset};
  box-shadow: $top $left $blur $color #{$inset};
}

@mixin transition($transition) {
  transition: $transition 0.25s;
  -webkit-transition: $transition 0.25s;
  -moz-transition: $transition 0.25s;
  -ms-transition: $transition 0.25s;
  -o-transition: $transition 0.25s;
}

@mixin transition-delay($transition-delay) {
  transition-delay: $transition-delay;
  -webkit-transition-delay: $transition-delay;
  -moz-transition-delay: $transition-delay;
  -ms-transition-delay: $transition-delay;
  -o-transition-delay: $transition-delay;
}

@mixin transform($transform) {
  transform: $transform;
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
}

@mixin clipPath {
  -moz-clip-path: polygon(0 0, 100% 38px, 100% 100%, 0% 100%);
  -ms-clip-path: polygon(0 0, 100% 38px, 100% 100%, 0% 100%);
  -o-clip-path: polygon(0 0, 100% 38px, 100% 100%, 0% 100%);
  clip-path: url("#Layer_1");
  clip-path: polygon(0 0, 100% 38px, 100% 100%, 0% 100%);
  -webkit-clip-path: polygon(0 0, 100% 38px, 100% 100%, 0% 100%);
}

@mixin column-count($count) {
  column-count: $count;
  -webkit-column-count: $count;
  -moz-column-count: $count;
}

// black shadow

@mixin button-transition {
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  -o-transition-duration: 0.25s;
  transition-duration: 0.25s;
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}

@mixin gradient-black {
  background: linear-gradient(
    0deg,
    rgba($black, 0.6) 50%,
    rgba($white, 0) 100%
  );
}

@mixin breadcrumb-arrow($border-left-color) {
  z-index: 0;
  content: " ";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: rem-calc(20) solid transparent;
  border-bottom: rem-calc(20) solid transparent;
  border-left: rem-calc(10) solid $border-left-color;
  top: 0;
  margin-top: 0;
  @include breakpoint(large up) {
    border-top: rem-calc(30) solid transparent;
    border-bottom: rem-calc(30) solid transparent;
    border-left: rem-calc(10) solid $border-left-color;
    top: 0;
    margin-top: 0;
  }
}

//Titles
@mixin main-title {
  font-size: rem-calc(30);
  line-height: rem-calc(40);
  @include breakpoint(large up) {
    font-size: rem-calc(48);
    line-height: rem-calc(60);
  }
}
@mixin main-title-lg {
  font-size: rem-calc(30);
  line-height: rem-calc(40);
  @include breakpoint(large up) {
    font-size: rem-calc(51);
    line-height: rem-calc(60);
  }
}
@mixin sub-title {
  font-size: rem-calc(38);
  text-transform: capitalize;
}
@mixin sub-title-lg {
  font-size: rem-calc(28);
  text-transform: capitalize;
}
@mixin sub-title-md {
  font-size: rem-calc(24);
  line-height: rem-calc(25);
  text-transform: capitalize;
}
@mixin sub-title-sm {
  font-size: rem-calc(14);
}
@mixin sub-title-xs {
  font-size: rem-calc(12);
}
@mixin small-title-black {
  font-size: rem-calc(18);
  font-weight: $font-weight-700;
  color: $black;
  text-transform: capitalize;
}

@mixin p-text-lg {
  font-size: rem-calc(20);
  line-height: rem-calc(28);
  @include breakpoint(large up) {
    font-size: rem-calc(28);
    line-height: rem-calc(32);
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}
