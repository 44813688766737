body {
  font-size: 1rem;
  @include breakpoint(large up) {
    font-size: 1.125rem;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-weight: $font-weight-700;
  color: $dark-yellow;
  margin-bottom: rem-calc(15);
  @include breakpoint(large up) {
    margin-bottom: $title-margin;
  }
}

h1 {
  @include main-title;
  @include breakpoint(large up) {
    font-size: rem-calc(48);
  }
}
h2 {
  @include sub-title;
  @include breakpoint(large up) {
    font-size: rem-calc(40);
  }
  @include breakpoint(medium down) {
    font-size: 2rem;
  }
}

h3 {
  font-size: 1.75rem;
  font-weight: 700;
}
.p-text-sm {
  font-size: 0.875rem;
  @include sub-title-lg;
}
h4 {
  @include sub-title-md;
}
h5 {
  @include sub-title-sm;
}
h6 {
  @include sub-title-xs;
}

//class mixins

.main-title {
  @include main-title;
  text-transform: capitalize;
}
.main-title-lg {
  @include main-title-lg;
  text-transform: capitalize;
}
.sub-title {
  @include sub-title;
}
.sub-title-lg {
  @include sub-title-lg;
}
.sub-title-md {
  @include sub-title-md;
}
.sub-title-sm {
  @include sub-title-sm;
}
.sub-title-xs {
  @include sub-title-xs;
}

.p-text-lg {
  @include p-text-lg;
}

.small-title-black {
  @include small-title-black;
}

.p-text-sm {
  font-size: 0.875rem;
  color: $black-desc;
}
.p-text-md {
  font-size: 1rem;
  color: $black-desc;
}
.p-text-normal {
  font-size: 0.875rem;
  @include breakpoint(large up) {
    font-size: 1.125rem;
  }
  color: $black !important;
}


// colors
.text-black {
  color: $black;
}

.text-white {
  color: $white;
}

.text-dark-blue {
  color: $light-blue;
}

.text-black-2 {
  color: $black-desc !important;
}

.transparent-bg {
  background-color: $transparent-bg;
}

.text-orange {
  color: $orange2 !important;
}

//fonts
.text-xs {
  font-size: rem-calc(12) !important;
}

//font-weights
.font-weight-300 {
  font-weight: $font-weight-300;
}

.font-weight-400 {
  font-weight: $font-weight-400;
}

.font-weight-500 {
  font-weight: $font-weight-500;
}

.font-weight-600 {
  font-weight: $font-weight-600;
}

.font-weight-700 {
  font-weight: $font-weight-700;
}

.font-weight-normal {
  font-weight: $font-weight-normal;
}