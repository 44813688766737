//article-page-layout
.inner-page-layout{
    box-shadow: $card-drop-shadow;
    margin-top: -4rem;
    background-color: $white;
    padding: $global-padding;
    position: relative;
    z-index: 0;
    @include breakpoint(large up){
        padding: 2.813rem 4rem ;
        margin-top: -8rem;
        
      
    }
    .button{
        width: 100%;
        font-size: rem-calc(14);
        font-family: $heading-font-family;
        min-width: auto;
        @include breakpoint(large up){
            padding:1.125rem 1.25rem;
            font-size: 0.9rem;
        }
    }
    .article-details-section{
        p{
            margin-bottom: rem-calc(30);
        }
       
    }
   
   
}


  


