.sec-pad {
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(40);
  @include breakpoint(large up) {
      padding-top: rem-calc(48);
      padding-bottom: rem-calc(48);
  }
}

.sec-pad-top {
  padding-top: rem-calc(48);
  @include breakpoint(large up) {
      padding-top: rem-calc(48);
  }
}

.sec-pad-bottom {
  padding-bottom: rem-calc(40);
  @include breakpoint(large up) {
      padding-bottom: rem-calc(48);
  }
}

// widths
.width-medium-85 {
  @include breakpoint(medium up) {
      width: 85%;
  }
}

.vertical-center {
  display: flex !important;
  align-items: center;
}


// margin
.margin-left-auto {
  margin-left: auto;
}

.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}

.margin-top-auto {
  margin-top: auto;
}

.margin-bottom-large {
  margin-bottom: 1.5rem;
  @include breakpoint(medium up) {
      margin-bottom: 1.9rem;
  }
  @include breakpoint(large up) {
      margin-bottom: 3rem;
  }
}

.margin-bottom-xlarge {
  margin-bottom: 1.5rem;
  @include breakpoint(medium up) {
      margin-bottom: 1.9rem;
  }
  @include breakpoint(large up) {
      margin-bottom: 4rem;
  }
}

// Gutter width
.gutter-60 {
  @include breakpoint(large up) {
      margin-left: -2rem;
      margin-right: -2rem;
      &>.cell {
          margin-left: 2rem;
          margin-right: 2rem;
          width: calc(100% - 4rem);
      }
      &.large-up-3 {
          .cell {
              width: calc(33.33333% - 4rem) !important;
          }
      }
      &.large-up-2 {
          .cell {
              width: calc(50% - 4rem) !important;
          }
      }
  }
}

// flex
.flex-flow-row-wrap {
  flex-flow: row wrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

// hide class
.show-for-xlarge {
  display: none;
  @include breakpoint(xlarge up) {
      display: block;
  }
}

@include breakpoint(xlarge up) {
  .hide-for-xlarge {
      display: none;
  }
}

@include breakpoint(medium down) {
  p {
      margin-bottom: $global-margin;
  }
}

//padding-x
.padding-y-xxl {
  padding-top: 7.25rem;
  padding-bottom: 11rem;
}

.padding-top-xxl {
  padding-top: 7.25rem;
}

// text
.text-center-mobile {
  @include breakpoint(medium down) {
      text-align: center;
  }
}

.body-ht {
  min-height: 90vh;
}


