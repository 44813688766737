.tabs-container{
  @include breakpoint(medium down){
 padding-bottom: $global-padding;
  }
  .accordion-title{
    font-size: 1rem;
    color: #B2752D;
    font-weight: 600;
    text-transform: capitalize;
    &:hover , &:focus{
      background-color: transparent;
      color: #B2752D !important;
    }
  }
  .tabs {
    background-color: $half-white;
    border: none;
    color: $black-desc;
    @include flex;
    justify-content: center;
    &-title {
      
      a {
        margin-bottom: 0;
        font-size: rem-calc(18);
        color: $orange2;
        font-weight: $font-weight-700;
        font-family: $heading-font-family;
        text-transform: capitalize;
        @include breakpoint(large up) {
          padding: 1.25rem 3rem;
        }
        &:hover,
        &:focus,
        &[aria-selected="true"] {
          background: $white;
          color: $orange2;
        }
      }
    }
  }
  
}

.tabs-subtitle {
  color: inherit;
  font-weight: $font-weight-400;
}
.tabs-content {
  border: none;
  color: $black-desc;
  
  .main-title{
    margin-bottom: 3rem;
    text-align: center;
  }
  .event-img{
    margin-bottom: 3rem;
  }
  .tabs-subtitle{
     text-align: center;
    margin-bottom: 1rem;
  }
  p{
    font-size: rem-calc(18);
    margin-bottom: 1rem;
  }
 .document-tab{
  p{
    text-align: center;
  }
 } 
.map-placeholder {
    min-height: rem-calc(350);
    
    .map {
      height: 100%;
      width: 100%;
    }
   
  }
}

.document-materials {
   .cell{
    &:last-child{
      .card{
        border-bottom: none;
      }
    }
   }
  .card {
    margin-bottom: 0;
    border-bottom: 1px solid $beige;
    &-section {
      padding: 1rem;
      flex-flow: row wrap !important;
      align-items: center;
      .small-title-black{
        margin-bottom: 0;
        text-transform: capitalize;
      }
    }
    
  }
  .file-name {
    font-size: rem-calc(14);
    color: $grey-dark-format;
    text-decoration: underline;
  }
  
}

.location-details{
 .map-placeholder{
  @include breakpoint(medium down) {
    margin-bottom: 1rem;
  }
 }
 .tabs-subtitle{
  text-align: left !important;
  } 
  p{
    text-align: left;
  }
}

#map{
  width:100%;
  height:100%;
}
@media screen and (max-width: 39.99875em) {
  #map {
      height: 0;
      padding-bottom: 100%;
  }
}

.applications-title{
  .main-title-lg{
    margin-bottom: 0;
  }
  .button{
    margin-bottom: 0;
  }
}


.leaders-list{
  h1{
    padding-top: 3rem;
    @include breakpoint(medium down){
      padding-top: 3rem;
    }
  }
  .event-tabs{
    .leaders-tabs{
      @include breakpoint(medium up){
        border-bottom: 1px solid #b2752d4d ;// CR
      }
      
      .tabs-container{
       @include breakpoint(medium down){
        padding-top: 1rem;
       }
       .accordion{
        margin-bottom: 0;
        .accordion-content{
          @include breakpoint(medium down){
            padding: 0;
            i{
              font-size: rem-calc(14);
              display: block;
              text-align: center;
              padding: rem-calc(25) rem-calc(0);
            }
          }
         }
        }
        
      }
    }
    .leader-img{
     @include breakpoint(medium down){
      padding: 1rem;
     }
    }
    .leaders-table{
      @include breakpoint(small down){
       text-align: center;
      }
    }
    @include breakpoint(medium up){
      img{
        max-width: rem-calc(98);
        height: 7rem;//CR
        object-fit: cover;
      }
    }
    .tabs{
      .is-active{
        a{
          border: 1px solid;
          border-bottom: none !important;
          border: 1px solid #b2752d4d ;// CR
        }
      }
    }
    .tabs-title {
     padding: rem-calc(10) rem-calc(0) 0;
     a{
      padding: rem-calc(25) rem-calc(30);
     }
     .p-text-sm{
      font-family: $body-font-family;
    font-weight: $font-weight-400;
    margin-bottom: 0;
     }
    
    }
  }
  .events-block{
    padding-top: 1rem;
    padding-left: 1rem;
    @include breakpoint(large up){
      padding-left: rem-calc(40);
      
    }
    h3{
      color: $orange;
      margin-bottom: 0;
      font-size: rem-calc(24);
    }
  }

  
  .tabs-panel{
    padding: 0;
  }
  .tabs{
    justify-content: flex-start;
  }
  .tabs-content{
    i{
      font-size: rem-calc(14);
      display: block;
      text-align: right;
      padding: rem-calc(25) rem-calc(30);
    }
    p{
      text-align: left;
    }
   
    .leaders-table{
      display: flex;
    }
  }
  .list-btns{
    .button{
      @include breakpoint(medium){
        max-width:rem-calc(140);
      }
    }
    @include breakpoint(large up){
      padding-right: 2rem;
    display: flex;
    justify-content: space-between;
      .button{
        min-width:rem-calc(180);
      }
    }
  }
}
