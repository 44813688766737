
.leader-card{
    img{
      height: 14rem !important;
      width: 100%;
      object-fit: cover;
    }
    .grey-btn{
      background-color: $dark-blue;
   
    }
    .img-btn{
      position: absolute;
      top: rem-calc(20);
      left: rem-calc(20);
      z-index: 2;
      
      color: $white;
      min-width: auto;
      &:hover{
        border: none;
      }
    }
    .card-section{
      text-align: center;
    }
    .leader-name{
      text-align: center;
      h3{
        margin-bottom: 0;
        font-size: rem-calc(24);
      }
      p{
        margin-bottom: 0.5rem;
      }
    }
    .subheader{
      line-height: inherit !important;
    }
  }
  .leader-dropdown{
    z-index:2;
  }
  
  
  .leader-card-with-img {
    h2{
      margin-top: 2rem;//CR
      margin-bottom: 2.75rem;//CR
    }
  }  

  
.leaders-list .event-tabs .tabs .is-active a{
  position: relative;
  &:before{
    content: '';
    width: 100%;
    position: absolute;
    background: $white;
    height: 1px;
    z-index: 2;
    bottom: -1px;
    left: 0;
  }

}
