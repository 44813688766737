

//membership section 
.member-sec-wrap{
    display: flex; flex-flow: row;
    .align-accordion{
        position: relative !important;
        margin-left: -20%;
        margin-right: rem-calc(50) !important;
        max-width: rem-calc(200) !important;
        left:0 !important;
    }
}

.membership-section{
    .card{
        margin-bottom: 2rem;
        min-height: calc(100% - 2rem);
        .card-section{
            h3{
                font-size: rem-calc(21);
                color: $black;
            }
        }
   }
   .cards-with-img-grid{
    min-height: rem-calc(500);
   }
    .years-accordion{
        text-align: center;
        @include breakpoint(large up){
            position:absolute;
            left: rem-calc(-150);
            max-width: rem-calc(300);
            text-align: right;
            margin-top: rem-calc(60);
        }
        .is-active{
            .active{
                color: $bright-yellow;
            }
        }
        .accordion-title {
            border: none;
            font-size: rem-calc(14);
            font-family: inherit;
            color: $grey-light;
            border-bottom: none;
            font-weight: $font-weight-700;
            &::before {
                display: none;
            }
            &:hover,
            &:focus,
            &:active {
                background: none;
            }
        }
        .accordion-content {
            border: none;
            padding: 0.5rem 1rem;
            color: $grey-dark;
            font-size: inherit;
            font-weight: $font-weight-700;
            cursor: pointer;
        }
    }
    .membership-date{
        text-align: center;
        width:100%;
        span{
            font-size: rem-calc(22);
            font-weight: $font-weight-700;
           text-transform: uppercase;
        }
       
    }
    .membership-grid{
        position: relative;
        .cards-with-img{
            p{
              margin-bottom: 1rem;
              font-weight: $font-weight-300;
              font-size: 0.875rem; 
            }
        }
    }
    .member-dropdown{
        @include breakpoint(medium down){
            text-align: center;
        }
        .member-label{
            text-align: left;
        }
    }
}

