.wholistic-approach {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: $half-white;
    width: 100%;
    height: calc(50% + 20px);
    z-index: -1;
  }
  .half-blue-bg , .dark-blue-bg{
    margin-bottom: 0;
  }
}
