

.advertisement-section{
    position: relative;
    .basic-content-with-bg {
        text-align: center;
       padding-top: rem-calc(60);
       padding-bottom: rem-calc(60);
       color: $white;
       font-weight: $font-weight-400;
       background-size: cover;
       background-repeat: no-repeat;
       object-fit: fill;
        h2, h3 {
            color: inherit;
            font-weight: inherit;
            font-size: 2.375rem;
            text-transform: capitalize;
        }
        .button{
            margin-bottom: 0;
        }
        &::before{
            content: "";
            background: $half-white;
            height: calc(50% - 1rem);
            width: 100%;
            z-index: -1;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}