      
.icon-placeholder {
  font-size: 3.5rem;
  @include breakpoint(medium up){
    padding-right: rem-calc(10);
  }
}

//breadcrumb 

.breadcrumb-block {
    margin-bottom: 1rem;
    background-color: $white;
    display: inline-flex;
    @include breakpoint(xlarge up) {
      display: inline-block;
      padding-right: 0;
    }
  }
  .breadcrumbs {
    margin-bottom: 0;
    @include breakpoint(medium down) {
      display: flex;
      white-space: nowrap;
      overflow-x: auto;
    }
    li {
      &::after {
        display: none;
      }
      a {
        position: relative;
        display: block;
        float: left;
        font-size: rem-calc(12);
        font-weight: $font-weight-500;
        padding: 0.45rem 0.8rem;
        text-transform: uppercase;
        color: $orange;
        font-family: $heading-font-family;
        @include breakpoint(large up) {
          padding: rem-calc(18) rem-calc(35) rem-calc(18) rem-calc(25);
          font-size: rem-calc(14);
        }
        &:hover {
          text-decoration: none;
        }
        &::after {
          @include breadcrumb-arrow($white);
          right: rem-calc(1);
        }
        &::before {
          @include breadcrumb-arrow($beige);
          right: 0;
        }
      }
      &:last-child {
        a {
          color: $black-alt;
          &::after {
            border: none;
          }
          &::before {
            border: none;
          }
        }
      }
    }
  }

  
//published date section

.published{
    &-block{
      margin-bottom: rem-calc(20);
        position: relative;
        background-color: $half-white;
        padding: $global-padding; 
    }
    &-text{
        font-size: rem-calc(14);
        font-weight: $font-weight-700;
        text-transform: capitalize;
       
    }
}
.category{
    text-align: center;
    .category-text{
    text-transform: uppercase;
    font-weight: $font-weight-700;
    font-size: 0.875rem;
   
    }
}


//download card

.download-card{
    &-section{
    padding: 1.5rem 1.5rem 1.6rem 0.7rem;
    margin-bottom: 1.5rem ;
    display: flex;
    }
    &-content{
        margin: 0;
        border-left: 0.4rem solid rgba($dark-yellow , 0.3);
        padding-left: 0.7rem;
        word-wrap: break-word;
        display: flex;
        flex-flow: column;

        .small-title-black{
            margin-bottom: 0.3rem;
        }
        .button{
          margin-bottom: 0;
          margin-top: auto;
        }
    }
    &-desc{
        font-size: rem-calc(14);
        text-transform: inherit;
        margin-bottom: 0.3rem;
        color: $black;
    }
}

//help modal
.help-modal{
    width: 60% !important;
    
    @include breakpoint(small down){
        width: 90% !important;
        margin: auto;
    }
    @include breakpoint(xlarge up){
        width: 45% !important;
        margin: auto;
        top: rem-calc(140) !important;
    }
    padding: 0;
    &-content{
        padding: 1.5rem 3.5rem;
        @include breakpoint(medium down){
            padding: 1.5rem;
        }
        .sub-title-xs{
          font-weight: $font-weight-700;
          text-transform: uppercase;
        }
        .sub-title{
            margin-bottom: 0.5rem;
        }
        p{
            margin-bottom: 1rem;
        }
        .member-label{
            margin-bottom: 0.2rem;
        }
    }
    &-form{
        padding: 1.5rem 4rem;
        @include breakpoint(medium down){
            padding: 1.5rem;
        }
        .message-box{
            box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.251) !important;
            border: 1px solid #e1dede;
        }
    }
    .close-button{ 
      text-transform: uppercase;
      text-decoration: underline;
        color: $orange-v2;
        font-size: rem-calc(18);
        font-weight: $font-weight-700;
        &:hover{
          color: $black-v2;
        }
        &:active, &:focus{
          color: $light-orange;
        }
       @include breakpoint(large up){
        right: 3rem;
        top: 1rem;
       }
    }
}

//dropdown
.member-label{
    font-size: 0.875rem;
    font-weight: $font-weight-500;
    margin-bottom: rem-calc(10);
}
.dropdown-sec{
    position: relative;
    select{
        background: $half-white;
        border: none;
        font-weight: $font-weight-700;
        text-transform: uppercase;
        font-size: 0.75rem;
    }
    i{
        position: absolute;
        top:rem-calc(10);
        right:rem-calc(10) ;
        pointer-events: none;
    }
}


//blue cards
.approach-card{
  min-height: 230px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: $white;
  &:hover{
    h3{
      color: #fff !important;
    }
  }
}

.community-blue-cards{
  
    .lol-inc-icon{
        font-size: 2.5rem;
        margin-top: auto;
    }
}


// html{
//     scroll-behavior: smooth;
//   }
// .smooth-scroll {
//   scroll-behavior: smooth;
// }

// .menu{
//    a{
//     &.smooth-scroll {
//       scroll-behavior: smooth;
//  }
//    }
// }


  
//blue full width section

.blue-full-width-section{
    .dark-blue-bg{
        @include breakpoint(medium up){
        display: flex;
        justify-content: space-between;
        }
        padding: rem-calc(20);
    }
    a{
        text-decoration: underline;
        margin-bottom: 0;
        margin-right: 1rem;
        font-size: 1rem;
        color: $bright-yellow;
        @include breakpoint(medium down){
            display: block;
        }
    }
    p{
      margin-bottom: 0;
    }
}
// .team-behind-the-scenes{
//     @include breakpoint(medium up){
//         width: 85%;
//         margin: auto ;
//         text-align: center;
//     }
// }
.card{
  .subheader{
    margin-bottom: 2rem;
  }
}

//heading border
.heading-border{
    border-bottom: rem-calc(6) solid $dark-border;
}

//search-result-cards
.search-result-cards{
    .result-block{
        border-bottom:  1px solid $light-border;
        // padding: rem-calc(30) rem-calc(40);
        padding: 1.175rem 2.5rem;// CR
        // min-height: rem-calc(180);
        min-height: 5.25rem;//CR
        .small-title-black{
            margin-bottom: 0.3rem;
        }
    }
    .result-title{
     display: flex;
     justify-content: space-between;
    text-transform: uppercase;
     .date{
        font-size: rem-calc(12);
        color: $orange2;
     }
     .title{
        font-size: rem-calc(12);
        color: $orange2;
       @include breakpoint(medium up){
        margin-top: rem-calc(10);
        margin-bottom:0;
       }
     }
    
    }
        .result-desc{
            @include breakpoint(medium up){
                width: 75%;
            }
        }
    
}

//select dropdown


  // p {
    
  //   a {
      
  //     display: inline-block;
      
  //     background-color: $half-white;
  //     @include transition(all 0.2s ease-in);
  //     &:hover {
  //       background-color: $half-white;
  //     }
  //   }
  // }
  .select-hidden {
    display: none;
    visibility: hidden;
    padding-right: 10px;
  }
  .select {
    cursor: pointer;
    display: inline-block;
    position: relative;
   font-size: rem-calc(12);
   font-weight: $font-weight-700;
    color: $select-color;
    width: $select-width;
    height: $select-height;
 
  }
  .select-styled {
    color: $black;
    position: absolute; 
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $half-white;
    padding: 8px 15px;
    text-transform: uppercase;
    @include transition(all 0.2s ease-in);
    display: flex;
    align-items: center;
    &:hover {
      background-color: $white;
    }
    &:active, &.active {
      background-color: $white;
     
      &:after {
        top: 9px;
        border-color: transparent transparent $black-alt transparent;
      }
    }
  }
  
  .select-options {
    display: none; 
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 999;
    margin: 0;
    padding: 0;
    list-style: none;
    color: $black;
    background-color: $white;
    font-weight: $font-weight-500;
    text-align: left;
    text-transform: capitalize;
    li {
      &:first-child{
        text-transform: uppercase !important;
        font-weight: $font-weight-700;
      }
      margin: 0;
      padding: 12px 0;
      text-indent: 15px;
      border-top: 2px solid darken($select-background, 10);
      line-height: 1rem;
      @include transition(all 0.15s ease-in);
      &:hover, 
      &.is-selected {
        color: $black;
        background: $half-white;
       
      }
      &.is-selected {
        color: $grey-dark;
      
      }
      &[rel="hide"] {
        display: none;
      }
    }
  }

  .accordion
  {min-width:rem-calc(120);}






//multiple select
.multiple-select{
  position: relative;
  z-index: 0;
  .filter-multi-select{
    padding: rem-calc(10);
    background: #f5f4f1;
    border: none;
    cursor: pointer;
    position: relative;
    font-size: 0.75rem;
    font-weight: 700;
    align-items: center;
    .dropdown-toggle{
      &::before{
        display: none;
      }
    }
    .filter{
      display:none;
    }
    .dropdown-menu{
      width: 100%;
    }
    .viewbar{
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: $font-weight-700;
    }
  }
  .lol-inc-icon{
    position: absolute;
    top: rem-calc(14);
    right: rem-calc(10);
    pointer-events: none;

  }
}


.pagination-block{
  .pagination{
    padding: 1rem 0;
    li{
      font-size: 1rem;
    }
    .current{
      font-weight: 700;
    }
  }
}



.scroll {
  cursor: pointer;
  width: 70px;
  height: 70px;
  position: fixed;
  bottom: 40px;
  right: -80px;
  padding-top: 18px;
  border-radius: 100%;
  // background-image: radial-gradient(circle farthest-corner at 10% 20%, #5a5c6a 0%, #202d3a 81.3%);
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  // -webkit-box-shadow: 0 0 5px 0px #888;
  // box-shadow: 0 0 5px 0px #888;
  -webkit-transition: 300ms;
  transition: 300ms;
  @include breakpoint(medium down){
    height: 45px;
    width: 45px;
    padding-top: 12px;
    font-size: 14px;
    right: 15px;
    bottom: 15px;
  }
}

.scroll i {
  // margin-top: 10px;
  // text-shadow: 0 0 2px #fff;
  color: $orange;
  font-size: 3rem;
  @include breakpoint(small down){
    font-size: 2.5rem;
  }
  transform: rotate(180deg);
  font-weight: $font-weight-500;
  &::before{
    background-color: #fff;
    border-radius: 50%;
  }
}

.scroll:hover i {
  animation-name: rotate;
  animation-duration: 300ms;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes rotate {
  from {margin-top: 15px}
  to {margin-top: 5px}
}

.visible {
  right: 30px;
  transition: all 400ms;
  transform: rotate(360deg)
}

//video-modal
.video-modal-layout{
  height: auto;
  min-height: auto;
 @include breakpoint(medium up){
  top: 4.75rem !important;
  width: 75% !important;
 }
  .help-modal-content{
    padding: 3.5rem 1rem;
    @include breakpoint(medium up){
    padding:3.5rem;
    }
    .video-post{
      margin-bottom: 0;
      .video-thumbnail{
        margin-bottom: 0;
      }
    }
  }
  .close-button{
    top: 1.5rem;
  }
}

//preloader
/* Your Preloader css codes start here: */

/*
 * 2.0 -> Preloader
 * -----------------------------------------------
*/
// .preloader-icon{
//   min-height: 90vh;
// }
#preloader {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
}

#spinner {
  display: block;
  height: 110px;
  left: 50%;
  margin: -55px;
  position: relative;
  top: 50%;
  width: 110px;
}

#preloader #disable-preloader {
  display: block;
  position: absolute;
  right: 30px;
  bottom: 30px;
}


/*===== Preloader Fourteen =======*/

/*=======Markup=====

<div class="preloader-orbit-loading">
	<div class="cssload-inner cssload-one"></div>
	<div class="cssload-inner cssload-two"></div>
	<div class="cssload-inner cssload-three"></div>
</div>
*/

.preloader-orbit-loading {
  position: relative;
  left: calc(50% - 31px);
  margin-top: calc(50vh - 100px);
  // margin: auto;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  perspective: 780px;
  .cssload-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    &.cssload-one {
      left: 0%;
      top: 0%;
      animation: cssload-rotate-one 1.15s linear infinite;
      -o-animation: cssload-rotate-one 1.15s linear infinite;
      -ms-animation: cssload-rotate-one 1.15s linear infinite;
      -webkit-animation: cssload-rotate-one 1.15s linear infinite;
      -moz-animation: cssload-rotate-one 1.15s linear infinite;
      border-bottom: 3px solid $bright-yellow;
    }
    &.cssload-two {
      right: 0%;
      top: 0%;
      animation: cssload-rotate-two 1.15s linear infinite;
      -o-animation: cssload-rotate-two 1.15s linear infinite;
      -ms-animation: cssload-rotate-two 1.15s linear infinite;
      -webkit-animation: cssload-rotate-two 1.15s linear infinite;
      -moz-animation: cssload-rotate-two 1.15s linear infinite;
      border-right: 3px solid $orange;
    }
    &.cssload-three {
      right: 0%;
      bottom: 0%;
      animation: cssload-rotate-three 1.15s linear infinite;
      -o-animation: cssload-rotate-three 1.15s linear infinite;
      -ms-animation: cssload-rotate-three 1.15s linear infinite;
      -webkit-animation: cssload-rotate-three 1.15s linear infinite;
      -moz-animation: cssload-rotate-three 1.15s linear infinite;
      border-top: 3px solid $nav-dark-blue;
    }
  }
}

@keyframes cssload-rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}


@-o-keyframes cssload-rotate-one {
  0% {
    -o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    -o-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}


@-ms-keyframes cssload-rotate-one {
  0% {
    -ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    -ms-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}


@-webkit-keyframes cssload-rotate-one {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}


@-moz-keyframes cssload-rotate-one {
  0% {
    -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}


@keyframes cssload-rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}


@-o-keyframes cssload-rotate-two {
  0% {
    -o-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    -o-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}


@-ms-keyframes cssload-rotate-two {
  0% {
    -ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    -ms-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}


@-webkit-keyframes cssload-rotate-two {
  0% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}


@-moz-keyframes cssload-rotate-two {
  0% {
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}


@keyframes cssload-rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}


@-o-keyframes cssload-rotate-three {
  0% {
    -o-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    -o-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}


@-ms-keyframes cssload-rotate-three {
  0% {
    -ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    -ms-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}


@-webkit-keyframes cssload-rotate-three {
  0% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}


@-moz-keyframes cssload-rotate-three {
  0% {
    -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}



/* -------- Preloader animation ---------- */

.pt-30{
	padding-top: 30px;
}

.pb-30{
	padding-bottom: 30px;
}

.mt-60{
	margin-top: 60px
}

.mb-60{
	margin-bottom: 60px
}



/*===== Preloader Eleven =======*/

/*=======Markup=====

<div class="preloader-dot-circle-rotator"></div>

*/

.preloader-dot-circle-rotator {
  position: relative;
  width: 12px;
  height: 12px;
  left: 46%;
  left: calc(50% - 6px);
  left: -o-calc(50% - 6px);
  left: -ms-calc(50% - 6px);
  left: -webkit-calc(50% - 6px);
  left: -moz-calc(50% - 6px);
  border-radius: 12px;
  background-color: $nav-dark-blue;
  transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  animation: cssload-loader 1.15s ease-in-out infinite;
  -o-animation: cssload-loader 1.15s ease-in-out infinite;
  -ms-animation: cssload-loader 1.15s ease-in-out infinite;
  -webkit-animation: cssload-loader 1.15s ease-in-out infinite;
  -moz-animation: cssload-loader 1.15s ease-in-out infinite;
  &:before {
    content: "";
    position: absolute;
    background-color: $orange;
    top: 0px;
    left: -24px;
    height: 12px;
    width: 12px;
    border-radius: 12px;
  }
  &:after {
    content: "";
    position: absolute;
    background-color: $bright-yellow;
    top: 0px;
    left: 24px;
    height: 12px;
    width: 12px;
    border-radius: 12px;
  }
}

@keyframes cssload-loader {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(180deg);
  }
}


@-o-keyframes cssload-loader {
  0% {
    -o-transform: rotate(0deg);
  }

  50% {
    -o-transform: rotate(180deg);
  }

  100% {
    -o-transform: rotate(180deg);
  }
}


@-ms-keyframes cssload-loader {
  0% {
    -ms-transform: rotate(0deg);
  }

  50% {
    -ms-transform: rotate(180deg);
  }

  100% {
    -ms-transform: rotate(180deg);
  }
}


@-webkit-keyframes cssload-loader {
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
  }
}


@-moz-keyframes cssload-loader {
  0% {
    -moz-transform: rotate(0deg);
  }

  50% {
    -moz-transform: rotate(180deg);
  }

  100% {
    -moz-transform: rotate(180deg);
  }
}

.hr-border{
  border-bottom: 1px solid #b2752d4d ;
}


//prelogin page
.pre-login-page{
  background-color: black;height: 100vh;padding-top: 50vh;position:absolute;top: 0;  width: 100%;z-index: 1;width:100%;
  .pre-login-content{
    color: $white;
    margin: auto; 
    @include breakpoint(medium up){
    max-width: none;
    }
    i{
      @include breakpoint(medium up){
     font-size: 4rem;
      }
    }
  }
}



//custom dropdown

.region-filter {
  display: block;
  background-color: #f5f4f1;
  li {
      a {
          font-size: 0.7rem;
          color: #020001;
          padding: 0.9rem 1rem;
          &:hover,
          &:focus {
              color: #020001;
          }
          &#test1 {
              text-transform: uppercase;
              font-weight: bold;
              padding: 0.9rem 1rem;
              color: #020001;
              &:hover,
              &:focus {
                  color: #020001;
              }
              &:after {
                  border-color: #020001 transparent transparent;
                  right: 10px;
              }
          }
      }
      .menu {
          width: 100%;
          border: none;
          li {
              border-bottom: 1px solid #ddd;
              a {
                  padding: 0.9rem 1rem;
                  font-weight: bold;
                  &:hover {
                      background-color: #f5f4f1;
                  }
              }
          }
      }
  }
}


//drop-shadow
.card-drop-shadow {
  box-shadow: $card-drop-shadow;
}



// white background
.white-bg {
  background-color: $white;
}



.half-white-bg {
  background-color: $half-white;
}

.error-page {
  h1 {
      @include breakpoint(medium up) {
          font-size: 8rem;
          line-height: normal;
      }
  }
  img {
      width: rem-calc(150);
      margin-bottom: 1.5rem;
      @include breakpoint(medium up) {
          width: rem-calc(300);
      }
  }
}

// a{
//   color: #A46F33 !important;
//   &:hover{
//     color: #0E121D !important;
//   }
// }